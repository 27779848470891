//import store from "../../store";
const termineRoutes = [
  {
    path: "/Termine",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Termine.vue"
      ), 
      
  },
];

export default termineRoutes;
