// import VueCookies from "vue-cookies";



const mutations = {
  setActiveSite(site, activeSite) {
    site.activeSite = activeSite;
    //   console.log("neuer Wert " + site.activeSite);
  },
};


const getters = {
  getActiveSite: (site) => {
    // console.log("gebe zurück: " + site.activeSite);
    return site.activeSite;
  },

};

const mainModule = {
  mutations,
  getters,
};

export default mainModule;
