<template>
  <div>
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"

export default {
  name: "App",
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";
@import "./eigen_css/kito.css";

h2 {
  font-size: 2rem;
  font-weight: bold;
}
h3 {
  font-size: 1.75rem;
  font-weight: bold;
}
h4 {
  font-size: 1.5rem;
  font-weight: bold;
}
p,
li {
  font-size: 18px;
}
a {
  text-decoration: none;
}
.row {
  padding: 0;
  margin: 0;
}
.fw0 {
  font-weight: normal;
}
.fw2 {
  font-weight: bold;
}
.background1 {
  background-image: url("assets/Header1.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-Hellgrau {
  background-color: #646363c9;
}
.bg-Dunkelgrau {
  background-color: #8a8a8a;
}
.bg-DunkelgrauTransparent {
  background-color: #8a8a8acc;
  margin: 0;
}
.bg-HellGruen {
  background-color: #90b4af;
}

.bg-WeissTransparent {
  background-color: rgba(255, 255, 255, 0.801);
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.bg-Weiss {
  background-color: rgb(255, 255, 255);
}
.text-Dunkelgrau {
  color: #5d595b;
}
.text-Rot {
  color: #a72527;
}

.bg-Rot {
  background-color: #a72527;
}
.text-Schwarz {
  color: black;
}
.text-Weiss {
  color: white;
}
.text-Weiss-Schatten {
  color: white;
  text-shadow: 3px 3px 0px #251616, 0px 12px 12px rgba(0, 0, 0, 0.15);
}

.text-Hellgrau {
  color: #cecfcb;
}
.text-Shadow {
  text-shadow: 0px 3px 0px #b2a98f, 0px 12px 12px rgba(0, 0, 0, 0.15);
}
.t-center {
  text-align: center;
}
.spacebetween {
  display: flex;
  justify-content: space-between;
}

.justifyCenter {
  display: flex;
  justify-content: center !important;
}
.justifyLeft {
  display: flex;
  justify-content: left !important;
  align-items: CENTER;
}
.scroll {
  overflow: auto;
  height: 700px;
}
.nav {
  background-color: rgb(255, 255, 255);
  color: black !important;
}

@media screen and (max-width: 500px) {
  /* CSS-Anweisungen für Bildschirmbreiten bis maximal 500 Pixel */
  html * {
    font-family: Verdana;
    font-size: 12px;
  }
  h1 {
    font-size: 22px;
    font-weight: bold;
  }
  .fontSize16 {
    font-size: 12px;
  }
  .nav-active {
    color: #a72527 !important;
    text-decoration: underline white;

    font-size: 10px;
  }
  .nav-deactiv {
    text-decoration: none;
    color: black !important;

    font-size: 10px;
  }
  .navFooter-active {
    font-size: 12px;
    color: white !important;
    text-decoration: underline white;
  }
  .navFooter-deactiv {
    font-size: 12px;
    text-decoration: none;
    color: white !important;
  }
  .text-Rot-Schatten {
    color: #d33033;
    text-shadow: 3px 3px 0px #4902024b, 0px 12px 12px rgba(0, 0, 0, 0.15);
    font-size: 22px;
  }

  .sticky {
    position: fixed;
    bottom: 10px;
    right: 10px;
    overflow: auto;
    color: #8b2229;
    text-shadow: 1px 1px 4px white;
  }
  .stickyShop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    overflow: auto;
    color: #8b2229;
    text-shadow: 1px 1px 4px white;
  }
  .modale {
    width: 100%;
    overflow: auto;
    max-height: 90%;
  }
  .modaleKlein {
    width: 100%;
    overflow: auto;
    height: 620px;
  }

  .modaleInner {
    height: 540px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .carusell{
    height: 240px;
  }
  .Slider{
    height:"200";
    width:"600";
  }
  .nurBildschirm{
    display: none;
  }
}

@media screen and (min-width: 500px) {
  /* CSS-Anweisungen für Bildschirmbreiten ab minimal 500 Pixel */
  html * {
    font-family: Verdana;
    font-size: 18px;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
  }
  .fontSize16 {
    font-size: 16px;
  }
  .nav-active {
    color: #a72527 !important;
    text-decoration: underline white;

    font-size: clamp(0.4rem, 1.3vw, 1.3rem);
  }
  .nav-deactiv {
    text-decoration: none;
    color: black !important;

    font-size: clamp(0.4rem, 1.3vw, 1.3rem);
  }
  .navFooter-active {
    font-size: 22px;
    color: white !important;
    text-decoration: underline white;
  }
  .navFooter-deactiv {
    font-size: 22px;
    text-decoration: none;
    color: white !important;
  }
  .text-Rot-Schatten {
    color: #d33033;
    text-shadow: 3px 3px 0px #4902024b, 0px 12px 12px rgba(0, 0, 0, 0.15);
    font-size: 32px;
  }

  .sticky {
    position: fixed;
    bottom: 150px;
    right: 50px;
    overflow: auto;
    color: #8b2229;
    text-shadow: 1px 1px 4px white;
  }
  .stickyShop {
    position: fixed;
    bottom: 150px;
    right: 150px;
    overflow: auto;
    color: #8b2229;
    text-shadow: 1px 1px 4px white;
  }
  .modale {
    width: 80%;
    overflow: auto;
    max-height: 90%;
  }

  .modaleKlein {
    width: 60%;
    max-width: 900px;
    overflow: auto;
    max-height: 90%;
  }
  .modaleInner {
    height: 620px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .nurHandy{
    display: none;
  }
}

.flag {
  max-height: 34px;
  padding-top: 5px;
}
.noDeko {
  list-style: none;
  padding: 0;
}

.paragraf {
  color: #a72527 !important;
  font-weight: bold;
}
.footer {
  background-color: #a72527;
  color: white !important;
}
.center {
  margin: auto;
  width: 50%;
  padding: 20px;
}
.zentriert {
  padding: 10% 0;
}
.imgRoundet {
  border-radius: 18px;
}
.roundedCorner {
  border: solid 1px #555;
  background-color: #eed;
  box-shadow: 10px 10px 5px rgba(82, 82, 82, 0.6);
  -moz-box-shadow: 10px 10px 5px rgba(82, 82, 82, 0.6);
  -webkit-box-shadow: 10px 10px 5px rgba(82, 82, 82, 0.6);
  -o-box-shadow: 10px 10px 5px rgba(82, 82, 82, 0.6);
  border-radius: 25px;
}
.borderRund {
  border: solid 1px #555;
  background-color: #eed;
  box-shadow: 5px 5px 5px rgba(82, 82, 82, 0.6);
  -moz-box-shadow: 5px 5px 5px rgba(82, 82, 82, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(82, 82, 82, 0.6);
  -o-box-shadow: 5px 5px 5px rgba(82, 82, 82, 0.6);
  border-radius: 25px;
  margin: 10px;
  cursor: pointer;

  text-align: center;
  font-size: clamp(0.4rem, 0.8vw, 1.2rem);
}
.rundKopf {
  font-size: clamp(0.6rem, 1vw, 1.7rem);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 1px;
}

.Mitteilungsblattfoto {
  height: 200px;
  width: 150px;
}

.logoObenLinks {
  position: absolute;
  width: 130px;
  top: 70px;
  left: 40px;
  z-index: 10;
}

.img-fluid {
  min-width: 100%;
  height: auto;
}
.heidehof {
  max-width: 90%;
}
.AFMTitel {
  max-width: 40%;
}

/* klein*/
@media screen and (max-width: 900px) {
  .AFM-Titel {
    font-size: 2em;
    color: black !important;
  }

  .bildKasten {
    min-width: 100%;
  }
  .fuerGross {
    display: none;
  }
  .header_Justify {
    justify-content: center !important;
  }
}

/* Gross */
@media screen and (min-width: 900px) {
  .kastentext {
    font-size: 18px;
  }
  .header_Justify {
    justify-content: center !important;
  }

  .AFM-Titel {
    font-size: 46px;
    color: white !important;
    text-shadow: 0px 4px 0px black, 50px 14px 10px rgba(0, 0, 0, 0.15);
  }
  .AFM-Titel2 {
    font-size: 36px;
    color: black !important;
  }
  .AFM {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.55);
    top: 330px;
    left: 174px;
    z-index: 10;
  }

  .textweiss,
  .textgrau {
    padding: 10px;
    margin: 30px;
  }
  .kastengrau {
    margin: 50px;
  }
  .bildKasten {
    margin: 50px;
  }

  .fuerKlein {
    display: none;
  }
}

/*  Image zoom */

img.zoom {
  transition: transform 0.8s;
  -moz-transition: transform 0.8s;
  -webkit-transition: transform 0.8s;
  -o-transition: transform 0.8s;
  -ms-transition: transform 0.8s;
}
img.zoom:hover {
  cursor: zoom-in;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  transform: scale(2, 2);
  -moz-transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  -o-transform: scale(2, 2);
  -ms-transform: scale(2, 2);
}

/* Slide effects */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Inconsolata|Oswald");
* {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@font-face {
  font-family: "Cambria";
  src: local("Cambria"), url(./fonts/Cambria.ttf) format("truetype");
}
</style>
