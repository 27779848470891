//import store from "../../store";
const sonstigesRoutes = [
  {
    path: "/Sonstiges",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Sonstiges.vue"
      ), 
      
  },
];

export default sonstigesRoutes;
