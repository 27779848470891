import { createStore } from "vuex";

import mainModule from "./modules/main";
import sprachModule from "./modules/sprachSelect";
import userModule from "./modules/user";
import userVerwaltungModul from "./modules/userverwaltung";
const store = createStore({
  modules: {      
    suw:mainModule,
    sprachSelect:sprachModule,
    userVerwaltungModul:userVerwaltungModul,
    user:userModule,
  },
});

export default store;
