//import store from "../../store";
const startRoutes = [
  {
    path: "/",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Start.vue"
      ), 
      
  },
];

export default startRoutes;
