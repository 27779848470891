//import store from "../../store";
const shopRoutes = [
  {
    path: "/Shop",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Shop.vue"
      ), 
      
  },
];

export default shopRoutes;
