import { reactive } from "vue";
import axios from "axios";
const state = reactive({
  user: {
    username: "",
    mail: "",
    usertype: "",
  },
});
const getters = {
  getUSER: (state) => {
    return state.user;
  },
};
const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
};
const actions = {
  setUSERLOGOUT(context) {
    const user = {
      username: "",
      mail: "",
      usertype: "",
      error:"",
    };
    context.commit("SET_USER", user);
  },
  async setUSER(context, payload) {
    const globalparam = payload.globalparam;
    const userdata = payload.userdata;
    // console.log(userdata);
    // console.log(globalparam.axiosConfig);
    const payload2 = {
      user: userdata.name,
      pw1: userdata.passwort,
      key: "gb7gtngfi6rg54brnt",
    };

   await axios
      .post(
        globalparam.Global_ApiPfad + "checkuser.php/",
        payload2,
        globalparam.axiosConfig
      )
      .then(function (response) {
        if (response.data.error) {
          let user = {
            username: "",
            mail: "",
            usertype: "",
            error:response.data.errorText,
          };
          context.commit("SET_USER", user);
        } else {
          let user = {
            username: response.data.user,
            mail: response.data.mail,
            usertype: response.data.typ,
            error: "",
          };
          context.commit("SET_USER", user);
        }
        // console.log(user);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

const userSelectModul = {
  state: state,
  actions,
  mutations,
  getters,
};

export default userSelectModul;
