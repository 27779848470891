import { reactive } from "vue";
import axios from "axios";
const state = reactive({
  users: [],
});
const getters = {
  getUSERS: (state) => {
    return state.users;
  },
};
const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
};
const actions = {
  async resetPW(context, payload) {    
    const globalparam = payload.globalparam;
    const payload2 = {
      key: "ouzFGtzcIOTZUvouzgv=)&gvGzuv",
      IDUser:payload.userdata.IDUser,
    };
    axios
      .post(
        globalparam.Global_ApiPfad + "resetPW.php/",
        payload2,
        globalparam.axiosConfig
      )
      .then(function (response) {
        console.log(response.data);
        let users = [];
        response.data.map(function (value) {
          value.id = value.IDUser;
          users.push(value);
        });
        context.commit("SET_USERS", users);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async deleteUser(context, payload) {    
    const globalparam = payload.globalparam;
    const payload2 = {
      key: "vb996bn0NT0n67TNtmtMtgnmG",
      IDUser:payload.userdata.IDUser,
    };
    axios
      .post(
        globalparam.Global_ApiPfad + "deleteUser.php/",
        payload2,
        globalparam.axiosConfig
      )
      .then(function (response) {
        console.log(response.data);
        let users = [];
        response.data.map(function (value) {
          value.id = value.IDUser;
          users.push(value);
        });
        context.commit("SET_USERS", users);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async editUSER(context, payload) {    
    const globalparam = payload.globalparam;
    const payload2 = {
      key: "ildrnvgcwotmzgxw45p",
      IDUser:payload.userdata.IDUser,
      user:payload.userdata.user,
      mail:payload.userdata.mail,
      type:payload.userdata.type,
    };
    axios
      .post(
        globalparam.Global_ApiPfad + "editUsers.php/",
        payload2,
        globalparam.axiosConfig
      )
      .then(function (response) {
        console.log(response.data);
        let users = [];
        response.data.map(function (value) {
          value.id = value.IDUser;

          users.push(value);
        });

        context.commit("SET_USERS", users);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async addUSER(context, payload) {
    const globalparam = payload.globalparam;
    console.log("addUSER");
    console.log(payload);
    const payload2 = {
      key: "etzkin4btkvj4etuzbknrubk",
      user:payload.userdata.user,
      mail:payload.userdata.mail,
      type:payload.userdata.type,
    };
    axios
      .post(
        globalparam.Global_ApiPfad + "addUsers.php/",
        payload2,
        globalparam.axiosConfig
      )
      .then(function (response) {
        console.log(response.data);
        let users = [];
        response.data.map(function (value) {
          value.id = value.IDUser;

          users.push(value);
        });

        context.commit("SET_USERS", users);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async setInitUSERS(context, payload) {
    const globalparam = payload.globalparam;
    console.log("setInitUSERS");
    // console.log(userdata);
    // console.log(globalparam.axiosConfig);
    const payload2 = {
      key: "gb7jhxrtiwuhme0r78cn3",
    };

    axios
      .post(
        globalparam.Global_ApiPfad + "getUsers.php/",
        payload2,
        globalparam.axiosConfig
      )
      .then(function (response) {
        console.log(response.data);
        let users = [];
        response.data.map(function (value) {
          value.id = value.IDUser;

          users.push(value);
        });

        context.commit("SET_USERS", users);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};

const userVerwaltungModul = {
  state: state,
  actions,
  mutations,
  getters,
};

export default userVerwaltungModul;
