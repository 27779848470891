import { reactive } from "vue";
const state = reactive({
  sprachID: 0,
});
const getters = {
  getSprachID: (state) => {
    return state.sprachID;
  },
};
const mutations = {
  SET_SPRACHID(state, sprachID) {
    state.sprachID = sprachID;
  },
};
const actions = {
  async setSprachID(context, id) {
    context.commit("SET_SPRACHID", id);
  },

 

  
};

const sprachenSelectModul = {
  state: state,
  actions,
  mutations,
  getters,
};

export default sprachenSelectModul;
