//import store from "../../store";
const galerieRoutes = [
  {
    path: "/Galerie",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Galerie.vue"
      ), 
      
  },
];

export default galerieRoutes;
