import startRoutes from "./start-router";
import vereinRoutes from "./verein-router";
import kontaktRoutes from "./kontakt-router";
import medienRoutes from "./medien-router";
import termineRoutes from "./termine-router";
import galerieRoutes from "./galerie-router";
import veranstaltungen from "./veranstaltungen-router";
import shop from "./shop-router";
import sonstiges from "./sonstiges-router";
import impressumRoutes from "./impressum-router";
import datenschutzRoutes from "./datenschutz-router";
import redaktionenRoutes from "./redaktionen-router";

const routes = [...startRoutes,
                ...vereinRoutes,
                ...medienRoutes,
                ...termineRoutes,
                ...galerieRoutes,
                ...veranstaltungen,
                ...sonstiges,
                ...shop,
                ...kontaktRoutes,
                ...datenschutzRoutes,
                ...impressumRoutes,
                ...redaktionenRoutes,
            ];

export default routes;
