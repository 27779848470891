import { createApp } from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";
import Vuex from 'vuex';

const app = createApp(App);



const globalParam = {
    axiosConfig: {
      crossDomain: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    //Global_ApiPfad: "http://mammillaria.eu/API/",
    Global_ApiPfad: "https://pillar.de/afm/",
    Global_Modus: process.env.NODE_ENV,
  };
  app.config.globalProperties.globalParam = globalParam;


app.use(store);
app.use(router);
app.use(Vuex);

app.mount("#app");

