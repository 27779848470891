//import store from "../../store";
const veranstaltungenRoutes = [
  {
    path: "/Veranstaltungen",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Veranstaltungen.vue"
      ), 
      
  },
];

export default veranstaltungenRoutes;
